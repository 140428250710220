
import {computed, defineComponent, onUpdated} from 'vue'
import {LoadEntity, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import EntityView from "@/components/base/common/EntityView.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import DocumentRelation from "@/views/document/DocumentRelation.vue";
import Notes from "@/views/note/Notes.vue";
import DocumentPanel from "@/views/document/DocumentPanel.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import DocumentService from "@/core/services/DocumentService";
import Swal from "sweetalert2";

export default defineComponent({
  name: "Document",
  components: {DocumentPanel, Notes, DocumentRelation, QuickAction, EntityView, DateTimeFormat},
  setup() {
    const entity = computed(() => store.state.DocumentModule.document);
    onUpdated(() => {
      const bread: any = []
      if (entity.value.caze?.id) {
        bread.push(
          {link: true, router: '/cases/' + entity.value.caze.id, text: 'View Case'},
        )
      }
      if (entity.value.claim?.id) {
        bread.push(
          {link: true, router: '/claims/' + entity.value.claim.id, text: 'View Claim'},
        )
      }

      if (entity.value.logReport?.id) {
        bread.push(
          {link: true, router: '/log-reports/' + entity.value.logReport.id, text: 'View Report'},
        )
      }
      if (entity.value.invoice?.id) {
        bread.push(
          {link: true, router: '/invoices/' + entity.value.invoice.id, text: 'View Invoice'},
        )
      }
      if (entity.value.policy?.id) {
        bread.push(
          {link: true, router: '/policies/' + entity.value.policy.id, text: 'View Policy'},
        )
      }
      bread.push({link: false, router: '', text: entity.value.name})
      setCurrentPageBreadcrumbsWithParams('Document View', bread)
    })

    return {
      entity,
      ...LoadPanel(),
      ...LoadEntity(Actions.LOAD_DOCUMENT),
    }
  },
  methods :  {
    onDownloadPdf(id) {
      Swal.showLoading()
      DocumentService.download(id).then(res => {
        let fileName = res.headers["content-disposition"].split("filename=")[1];
        let blob = new Blob(
          [res.data]
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        Swal.close()
      })
    }
  }

})
