
import { defineComponent } from "vue";
import { LoadPanel } from "@/core/composite/composite";

export default defineComponent({
  name: "ClaimLink",
  props: {
    claimId: {type: String, required: true},
    claimNumber: {type: String, required: true}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
