
import {defineComponent} from 'vue'
import CaseLink from "@/views/case/CaseLink.vue";
import PolicyLink from "@/views/policy/PolicyLink.vue";
import ClaimLink from "@/views/claim/ClaimLink.vue";
import InvoiceLink from "@/views/invoice/InvoiceLink.vue";

export default defineComponent({
  name: "DocumentRelation",
  components: {InvoiceLink, ClaimLink, PolicyLink, CaseLink},
  props: {
    document: {type: Object, required: true}
  }
})
